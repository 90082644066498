import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')];

export const server_loads = [];

export const dictionary = {
	"/": [4],
	"/(login-layout)/accept-invite": [12,[3]],
	"/(customer-layout)/balance": [5,[2]],
	"/(login-layout)/change-email": [13,[3]],
	"/(login-layout)/confirm-email": [14,[3]],
	"/(login-layout)/create-order": [15,[3]],
	"/(customer-layout)/expert/[id]": [6,[2]],
	"/(login-layout)/forgot-password": [16,[3]],
	"/(login-layout)/login-as": [18,[3]],
	"/(login-layout)/login": [17,[3]],
	"/(customer-layout)/order/list": [9,[2]],
	"/(customer-layout)/order/new": [10,[2]],
	"/(customer-layout)/order/[id]": [7,[2]],
	"/(customer-layout)/order/[id]/details": [8,[2]],
	"/(customer-layout)/profile": [11,[2]],
	"/(login-layout)/reset-password": [19,[3]],
	"/(login-layout)/sign-up": [20,[3]],
	"/(login-layout)/signup": [21,[3]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};